import { Modal } from '@src/components';
import { CONTENT } from '@src/config';
import { setSafetyModalOpen } from '@src/features/layout';
import { useAppDispatch, useAppSelector } from '@src/hooks';

const SafetyModal: React.FC = () => {
    const open = useAppSelector((state) => state.layout.safety_modal_open);
    const dispatch = useAppDispatch();

    if (!open) {
        return null;
    }

    const display_url = CONTENT.website.replace('https://', '');

    return (
        <Modal onClose={() => dispatch(setSafetyModalOpen(false))}>
            <h3 className="text-2xl my-4">
                <strong>IS THIS PAGE SAFE TO USE?</strong>
            </h3>
            <p className="my-3">
                <a
                    href={CONTENT.website}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {display_url}
                </a>{' '}
                is the only official dashboard for all {CONTENT.companyName}{' '}
                users, any other pages should be considered unsafe to use.
            </p>
            <p className="my-3">
                All data exchanged between you and our servers is protected with
                Cloudflare 256 bit encryption.
            </p>

            <h4 className="text-xl my-3">
                <strong>We will never ask you for:</strong>
            </h4>
            <ul className="ml-4 my-3">
                <li>Your wallet seed phrase</li>
            </ul>

            <p className="my-3">
                We take all possible precautions to make your experience with $
                {CONTENT.companyName} safe, however, your data is only as safe
                as you make it.
            </p>
        </Modal>
    );
};

export default SafetyModal;
